@import "@design";

@import '@design';
.display-only {
  // styles copied mostly from .v-messages__message
  @media #{$mobile} {
    margin-top: 4px;
  }

  &.print-mode {
    font-size: 0.9em;
  }

  .hint {
    display: block;
    padding-left: 0;
    margin-bottom: 4px;
    font-size: 0.8em;
    color: rgba(0, 0, 0, 0.54);

    @media print {
      display: none !important;
    }
  }
}
