@import "@design";

.pagination-details {
  font-size: 0.8em;
}
.daily-report-revisions {
  font-size: 0.95rem;
  .v-expansion-panel__header {
    background-color: hsl(0, 0, 95%);
    transition: background-color 0.3s ease;
    &:hover {
      background-color: hsl(0, 0, 93%);
    }
  }
  .single .v-expansion-panel__header {
    cursor: auto;
    &:hover {
      background-color: hsl(0, 0, 95%);
    }
  }
  .bold {
    font-weight: 700;
  }

  $new: rgb(211, 255, 196);
  $old: rgb(255, 198, 198);
  $updated: rgb(198, 228, 255);
  .old-val,
  .action-deleted {
    color: darken($old, 60);
  }
  .new-val,
  .action-added {
    color: darken($new, 60);
  }

  .action-updated {
    color: darken($updated, 40);
  }
}
