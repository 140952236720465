@import "@design";

@import '@design';

.list--wrap {
  position: relative;
  .loading--wrap {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    background-color: rgba(white, 0.5);
    transition: background-color 0.3s ease;
  }
}

.daily-notes-item {
  text-align: left;
  textarea {
    padding: 7px 0;
  }
  .daily-notes-item-title {
    font-size: 1.1rem;
    font-weight: bold;
    color: $gray-body;
  }
  .picklist-wrap {
    padding-bottom: 15px;
    .daily-notes-item-title {
      margin-right: 10px;
    }
  }
  .picklist-btn {
    &.required.invalid {
      background-color: $light-red !important;
      border: none;
      // border:1px solid red;
    }
  }

  &.conditional-field-item {
    padding-left: $gutter / 2;
  }

  // Target the first conditional field item after a non-conditional
  // &:not(.conditional-field-item) + .conditional-field-item { }
} // .daily-notes-item
