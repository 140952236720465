@import "@design";

.quick-links-title {
  text-align: right;
}
.quick-links {
  align-items: center;
  justify-content: center;

  @media #{$lt_980} {
    width: 100%;
  }

  @media (max-width: 800px) {
    .quick-links-title {
      display: none;
    }
  }
  > * {
    flex: 0 0 auto;
    margin-right: 7px;
    margin-bottom: 5px;
  }
  .quick-link {
    &.active {
      border: 1px solid transparent;
    }

    @media #{$lt_620} {
      height: 26px;
      // margin-right: 1px;
      padding: 0 5px;
      font-size: 0.85rem;
      border-radius: 26px;
    }
  }
}
