@import "@design";

@import '@design';
.text-input {
  @media #{$mobile} {
    width: 100%;
  }

  .error--text .v-input__slot {
    color: #ff5252 !important;
    border: 1px solid #ff5252;
  }

  @media print {
    .v-text-field .v-input__control {
      .v-input__slot {
        padding-right: 0;
        padding-left: 0 !important;
        ::placeholder {
          visibility: hidden;
          opacity: 0;
        }
      }
      .v-text-field__details {
        display: none;
      }
    }
  }
}
