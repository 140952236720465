@import "@design";

.add-shift-item-btn.btn-base.v-btn {
  width: 28px;
  min-width: 0 !important;
  height: 28px;
  .v-icon--right {
    margin: 0;
  }
}
