@import "@design";

.quick-links-row {
  position: sticky;
  top: 64px;
  z-index: 1;
  background-color: #757575;

  @media #{$lt_980} {
    top: 48px;
  }

  @media #{$lt_768} {
    top: 56px;
  }
}

.report-data {
  display: inline-block;
  padding: 10px;
}

.daily-report {
  // &.status-Completed {
  //   background-color:$green;
  // }

  > .container,
  .meta--wrap > .container {
    @media #{$gt_768} {
      width: 98%;
      max-width: 1440px;
      padding-right: 8px;
      padding-left: 8px;
    }
  }

  .meta--wrap > .container {
    @media #{$lt_1024} {
      width: 100%;
    }
  }
}

.save-button {
  &.v-btn--bottom {
    bottom: 24px;
  }
  &.v-btn--right {
    right: 24px;
  }
}

.progress {
  background-color: $gray-dark;
  .determinate {
    background-color: $teal;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}
.fade-enter-active {
  transition-delay: 0.3s;
}
