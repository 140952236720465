@import "@design";

.shift-item {
  &.working {
    justify-content: center;
    .shift-item--wrap {
      opacity: 0.2;
    }
  }
  &.draft,
  &.modified {
    border-left-color: $blue !important;
  }
  &.draft {
    $light-blue: desaturate(lighten($blue, 35), 55);
    background-color: $light-blue !important;
    border-right-color: $light-blue !important;
  }
  &.invalid {
    border-left-color: $red !important;
  }
  &.default-shift {
    background-color: #effaf6 !important;
  }
}
.shift-item-flex {
  .v-text-field {
    .v-input__slot,
    .v-input__control {
      min-height: 30px;

      @media #{$gt_mobile} {
        height: 30px;
      }
    }
  }
  .v-input.v-autocomplete,
  .phase-select,
  .code-select {
    &.v-text-field.v-text-field--enclosed .v-input__slot {
      padding: 0 6px;
    }
    .v-select__slot {
      .v-select__selection,
      .v-label,
      input {
        font-size: 14px;
      }
    }
  }
  &.sunday-shift--wrap,
  &.equipment-shift--wrap {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .sunday-shift {
    word-break: break-word;
    font-size: 0.75em;
    font-style: italic;
    color: lighten(#000, 40);
  }
}
