@import "@design";

.shift-item {
  &.invalid {
    border-left-color: $red !important;
  }
}
.shift-item-flex {
  .v-text-field {
    .v-input__slot,
    .v-input__control {
      min-height: 30px;

      @media #{$gt_mobile} {
        height: 30px;
      }
    }
  }
  .v-input.v-autocomplete,
  .phase-select,
  .code-select {
    &.v-text-field.v-text-field--enclosed .v-input__slot {
      padding: 0 6px;
    }
    .v-select__slot {
      .v-select__selection,
      .v-label,
      input {
        font-size: 14px;
      }
    }
  }
  &.equipment-shift--wrap {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .equipment-shift-item {
    word-break: break-word;
    font-size: 0.75em;
    font-style: italic;
    color: lighten(#000, 40);
  }
}
