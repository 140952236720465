@import "@design";

.cost-code-picklist .v-input {
  label,
  input {
    font-size: 13px;
    color: black;
    text-transform: uppercase;
  }
}
