@import "@design";

.timepicker {
  .v-icon {
    font-size: 18px;
  }
  .v-select__slot input {
    font-size: 14px;
  }
}
