@import "@design";

.list--header {
  max-width: none;
  padding: 0 (($gutter * 2) - 4) 0 (($gutter / 2) + 2);
  font-weight: bold;
  color: $gray-dark;

  @media #{$mobile} {
    padding-right: 10px;
  }
  &.cannot-remove {
    padding: 0 10px;
  }
}
.col-header {
  padding: 0 2px;
  text-align: left;
}
