@import "@design";

.list-item-remove-btn.remove-btn {
  top: 50%;
  transform: translateY(-50%);
  .v-icon--right {
    margin: 0;
  }

  @media #{$mobile} {
    top: 20px;
  }
}
