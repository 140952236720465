@import "@design";

.revision-header {
  .revision-date {
    font-size: 0.8rem;
    color: #888;
  }
}
.author-name {
  margin-right: 3px;
  font-weight: 500;
}
