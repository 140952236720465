@import "@design";

@import '@design';
.daily-card--header {
  padding: $gutter / 2 $gutter;
  font-size: 1.4rem;
  font-weight: bold;
  color: black;
  text-align: left;
  background: $gray-light;
}
.list--wrap {
  padding: $gutter;
  transition: all 0.3s ease;
  &.empty {
    padding: $gutter * 3 $gutter;
  }
}

.list-item-field {
  width: 100%;
  padding: 3px 5px 3px 0;
  margin-right: 7px;
  text-align: left;
  border: 1px solid transparent;
  transition: border-color 0.3s ease;
  &.btn-with-icon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.9rem;

    @media #{$mobile} {
      display: block;
      font-size: 1.1rem;
    }
  }
  &.required,
  &[class*='required-type'] {
    &.invalid {
      border: 1px solid red;
    }
  }
}

.item-details {
  width: 100%;
}

.list-item-field--wrap {
  overflow: hidden;
  // height:100%;
  &.bold {
    font-weight: bold;
  }
  &.editable {
    padding: 0;
    .list-item-field:not(.type-checkbox) {
      padding-left: 5px;
      cursor: pointer;
      background-color: white;
      transition: background-color 0.3s ease;
      &:hover {
        background-color: rgba(white, 0.3);
      }
      // border-bottom:1px solid transparent;
    }
  }
  .picklist-btn {
    &.required.invalid {
      background-color: $light-red;
      border: none;
      // border:1px solid red;
    }
  }
}

.errors-list {
  li {
    margin-bottom: 10px;
  }
}

$dim: 20px;

.checkbox--wrap {
  label {
    height: $dim;
  }
}

.remove-btn,
.save-assgnt-btn {
  &,
  &:focus,
  &:hover {
    position: absolute;
  }

  right: 10px;
  width: $dim;
  height: $dim;
  line-height: $dim;

  @media #{$mobile} {
    top: 10px;
  }

  .material-icons {
    font-size: 1rem;
    line-height: $dim;
  }
}
.multi-select-all-checkbox {
  padding-top: 0;
  .v-input--selection-controls__input {
    margin-right: 0;
  }
}
