@import "@design";

.material-picklist .v-input {
  label,
  input {
    font-size: 13px;
    color: black;
    text-transform: uppercase;
  }
}
.daily-list-item.special-material {
  border: 2px solid #2196f3 !important;
  @media print {
    border-color: black !important;
    border-width: 2px 0 !important;
  }
}
