@import "@design";

.location-select {
  &.v-text-field.v-text-field--enclosed .v-input__slot {
    padding: 0 6px;
  }
  .v-select__selection {
    font-size: 14px;
  }
}
