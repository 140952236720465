@import "@design";

.daily-list-item {
  position: relative;
  padding: $gutter / 2;
  text-align: left;
  background-color: $gray-light;
  border: 2px solid transparent;

  &:not(.cannot-remove) {
    padding-right: $gutter * 2;
  }

  .assgnt--wrap {
    transition: opacity 0.3s ease;
    > .container {
      max-width: none;
    }
    .layout.wrap {
      @media print {
        flex-wrap: nowrap;
        margin: 0;
      }
    }
  }

  &.working {
    justify-content: center;
    .assgnt--wrap {
      opacity: 0.2;
    }
  }
  &.draft,
  &.modified {
    border-left-color: $blue !important;
  }
  &.draft {
    background-color: desaturate(lighten($blue, 35), 55) !important;
  }

  @media #{$mobile} {
    padding-right: 10px;
  }

  textarea {
    padding: 7px 0;
  }
  .daily-list-item--title {
    font-size: 1.1rem;
    font-weight: bold;
    line-height: 1.1;
    color: $gray-dark;

    @media screen and #{$mobile} {
      font-size: 1.4rem;
      line-height: 1.4;
    }
  }
  .picklist-wrap {
    padding-bottom: 15px;
    .daily-list-item--title {
      margin-right: 10px;
    }
  }
  .picklist-btn {
    &.required.invalid {
      background-color: $light-red !important;
      border: none;
      // border:1px solid red;
    }
  }

  &.conditional-field-item {
    padding-left: $gutter / 2;
  }

  .remove-btn,
  .save-assgnt-btn {
    width: 20px;
    min-width: 0 !important;
    height: 20px;
    margin: 0;
  }
  .working-spinner {
    position: absolute;
  }
} // .daily-list-item
