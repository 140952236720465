@import "@design";

@media print {
  // stylelint-disable-next-line
  #daily-report-container {
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin: 0;
    .hint,
    label {
      font-size: 16px !important;
      font-weight: bold !important;
    }
    .flex .hint {
      // display: inline-block;
      width: 120px;
    }
  }

  .meta--wrap.container {
    padding-right: 0;
    padding-left: 0;
    margin-bottom: 12px;
  }

  //
  // Daily Cards
  //
  .daily-card {
    .daily-card--header {
      padding: 0;
      margin-bottom: 7px;
      font-size: 1.3rem;
    }
    .list--wrap {
      padding: 0;
    }
    .list--header {
      padding: 0;
    }
    .col-header {
      font-weight: bold;
    }
    .daily-list-item {
      padding: 0.3rem 0 0;
      margin: 0 !important;
      background: none !important;
      border-color: $gray-body;
      border-style: dashed;
      border-width: 1px 0 0 0;
      page-break-inside: avoid;
      &:last-child {
        padding-bottom: 0.3rem;
        border-bottom: 1px dashed $gray-body;
      }

      @media print {
        padding: 0;
      }
    }
    .list-item-field--wrap {
      .list-item-field {
        border: 0;
        .btn-with-icon {
          font-size: 0.9rem;
        }
      }
      .printable-checkbox {
        .material-icons {
          font-size: 1.4rem;
        }
      }
      &.bold {
        font-weight: normal;
      }
      &.editable .list-item-field:not(.type-checkbox) {
        padding: 0;
        text-transform: none;
      }
    }
  }
} // @media print
